<template>
    <div>
        <!-- 제목 & 기간(Global) -->
        <div
            class="d-flex align-center px-5 pt-3 pb-3 mobile"
        >
            <!-- 제목 -->
            <p
                style="font-size:16px; font-weight:bold;"
                class="mb-0"
            >
                플레이어 정보
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                class="ml-2"
                indeterminate
                color="primary"
                size="18"
                width="2"
            ></v-progress-circular>

            <v-spacer></v-spacer>

            <v-btn
                depressed
                dark
                class="rounded-10 ml-2"
                style="font-size:12px;"
                color="primary"
                width="46"
                height="28"
                @click="$router.push('/league/player/list')"
            >
                목록
            </v-btn>
        </div>

        <!-- 플레이어 정보 상단 -->
        <v-sheet
            class="py-3 px-6 d-flex align-center"
            style="background:#424361;"
        >
            <!-- 프로필 아이콘 -->
            <v-sheet
                width="60"
                height="60"
                class="rounded-circle d-flex justify-center align-center"
            >
                <v-img
                    class="ml-2px"
                    height="36"
                    width="36"
                    contain
                    src="@/assets/admin_profile.png"
                ></v-img>
            </v-sheet>

            <!-- 플레이어 & 이번주 순위 -->
            <div
                class="ml-3"
            >
                <p
                    class="white--text mb-0"
                    style="font-size:12px; font-weight:bold;"
                >
                    {{$route.query.player}}
                </p>
                <p
                    class="white--text mb-0"
                    style="font-size:9px;"
                >
                    이번주 순위 0위 (상위 0%)
                </p>
            </div>

            <v-spacer></v-spacer>

            <!-- SNS & 국기 -->
            <div
                class="d-flex align-center justify-end"
            >
                <p
                    class="white--text mb-0"
                    style="font-size:12px;"
                >
                    SNS
                </p>

                <v-img
                    class="ml-3 rounded-5"
                    width="32"
                    height="28"
                    :src="'/upload/flags/'+player.country?.toLowerCase()+'.svg'"
                ></v-img>
            </div>
        </v-sheet>

        <!-- 플레이어 등급 -->
        <v-sheet
            class="ma-2 px-4 pt-4 pb-5 rounded-10 d-flex align-center"
            outlined
        >
            <!-- 등급 -->
            <v-sheet
                width="60"
                height="44"
            >
                <v-img
                    contain
                    src="@/assets/player.svg"
                ></v-img>
            </v-sheet>

            <!-- 등급 & RP & 승률(승패무) -->
            <div
                class="ml-3"
            >
                <!-- 등급 & RP -->
                <p
                    class="mb-0"
                >
                    <!-- 등급 -->
                    <span
                        style="color:#FF7A51; font-weight:bold; font-size:12px;"
                    >
                        레전드
                    </span>

                    <!-- RP -->
                    <span
                        class="ml-1"
                        style="color:#C8C9CF; font-weight:400; font-size:9px;"
                    >
                        RP {{$toComma(player.rp)}}
                    </span>
                </p>

                <!-- 승률(승패무) -->
                <p
                    class="mb-0"
                    style="font-size:9px;"
                >
                    승률 {{calculateWinRate(player.win, player.win + player.lose)}}% ({{player.win}}승 {{player.lose}}패 {{player.ties}}무)
                </p>
            </div>

            <v-spacer></v-spacer>

            <!-- SNS & 국기 -->
            <div
                class="mr-3 d-flex align-center justify-end"
            >
                <p
                    class="mb-0"
                    style="font-size:12px; font-weight:bold;"
                >
                    KR 0위
                </p>
            </div>
        </v-sheet>

        <!-- 티어 추이 -->
        <TierChart
            :deck_name="deck_name"
            :key="componentKey"
        />

        <!-- 대회 성적 -->
        <RaceRecord
            :deck_name="deck_name"
            :key="componentKey"
        />

        <!-- 덱 레시피 -->
        <DeckRecipe
            :deck_name="deck_name"
            :key="componentKey"
        />
    </div>
</template>
<script>
import TierChart from "./components/TierChart"
import RaceRecord from "./components/RaceRecord"
import DeckRecipe from "./components/DeckRecipe"

export default {
    components: {
        TierChart,
        RaceRecord,
        DeckRecipe
    },

    data: () => ({
        player: {
            country: "kr",
            rp: 0,
            win: 0,
            lose: 0,
            ties: 0,
        },

        deck_name: "",

        loading: false
    }),

    async mounted(){
        // 플레이어 정보 불러오기
        await this.loadPlayer()
    },

    methods: {
        // 플레이어 정보 불러오기
        loadPlayer(){
            this.loading = true

            this.$http.post("/api/league/player/select/detail", {
                params: {
                    player: this.$route.query.player
                }
            }).then((res) => {
                this.player.country = res.data[0].country
                this.player.rp = res.data[0].score
                this.player.win = res.data[0].total_wins
                this.player.lose = res.data[0].total_losses
                this.player.ties = res.data[0].total_ties

                this.deck_name = res.data[0].deck_name

                this.loading = false
                this.componentKey++
            })
        },

        calculateWinRate(winCount, total) {
            // 전체 게임 횟수가 0인 경우, 승률을 계산할 수 없으므로 0을 반환
            if (winCount + total === 0) {
                return 0
            }

            // 승률 계산
            return ((winCount / (total)) * 100).toFixed(2)
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 28px;
    height: 28px;
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 28px;
    height: 28px;
    transform: rotate(-45deg);
    display: block;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>