<template>
    <v-sheet
        class="ma-2 px-4 py-2 pb-3 rounded-10"
        outlined
    >
        <!-- 상단 -->
        <div
            class="d-flex align-center justify-space-between mb-4"
            :class="$vuetify.breakpoint.mobile? '':'mt-2'"
        >
            <!-- 제목 -->
            <div
                class="d-flex align-center"
            >
                <!-- PC -->
                <p
                    v-if="!$vuetify.breakpoint.mobile"
                    style="font-size:18px; font-weight:bold;"
                    class="my-0"
                >
                    메타 추이 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                </p>

                <!-- Mobile -->
                <p
                    v-if="$vuetify.breakpoint.mobile"
                    style="font-size:10px; font-weight:bold;"
                    class="mb-2 mt-2"
                >
                    메타 추이 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                </p>
            </div>

            <!-- 선택 상단 -->
            <SelectHeader
                :onlyTerm="true"
            />
        </div>

        <!-- 그래프 -->
        <Chart
            :height="$vuetify.breakpoint.mobile? 140 : 210"
            :meta_list="list"
            :term="keyword.term"
        />
    </v-sheet>
</template>
<script>
import SelectHeader from './components/SelectHeader'
import Chart from "./components/Chart"

export default {
    components: {
        SelectHeader,
        Chart
    },

    data: () => ({
        select_list: {
            term: [
                "최근 1개월",
                "최근 3개월",
                "최근 6개월",
                "최근 9개월",
                "최근 12개월"
            ]
        },

        keyword: {
            term: "최근 3개월"
        },

        list: []
    }),

    mounted(){
        // 목록 불러오기
        this.loadPlayerRanking()
    },

    methods: {
        // 목록 불러오기
        loadPlayerRanking(){
            this.$http.post("/api/league/deck/select/meta_chart", {
                params: {
                    // deck_name: "Snorlax Stall"
                    deck_name: this.$route.query.deck_name
                }
            }).then((res) => {
                // console.log(res)
                this.list = res.data
            })
        },
    }
}
</script>
<style scoped>
/* 테두리 색 변경 (성공) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:8px;
    height:33px !important;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
}

/* 날라가는 거 막는거 성공 (선택지 전체 높이) */
::v-deep .v-select__slot {
    height: 28px;
}

/* 날라가는 거 막는거 성공 */
::v-deep .v-select__selections {
    max-height: 28px !important;
    height: 28px !important;
    font-size: 9px;
}
</style>