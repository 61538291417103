<script>
import { Line } from 'vue-chartjs'
 
export default {
    extends: Line,

    props: [
        "list",
        "deck_name"
    ],

    data: () => ({
        label_list: [],
        specific_deck_list: []
    }),

    watch: {
        async list(){
            if(this.list){
                this.label_list = Array.from(new Set(this.list.map(e => e.month + "월 " + e.week_in_month + "째주")))
                this.specific_deck_list = this.list.filter(e => e.deck_name == this.deck_name)
            }

            await this.createGraph()
        }
    },

    async mounted(){
        if(this.list){
            this.label_list = Array.from(new Set(this.list.map(e => e.month + "월 " + e.week_in_month + "째주")))
            this.specific_deck_list = this.list.filter(e => e.deck_name == this.deck_name)
        }

        await this.createGraph()
    },

    methods: {
        // Chart 생성
        async createGraph(){
            this.renderChart({
                labels: this.label_list,
                datasets: [
                    {
                        data: this.specific_deck_list.map(e => e.score),
                        borderColor: "rgba(35, 210, 226, 0.44)",
                        backgroundColor: "rgba(35, 210, 226, 0.0)",
                        pointRadius: 2,
                        lineTension: 0
                    },
                ],
            },
            {
                tooltips: {
                    // 점 상세
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let tier = this.calculateTier(this.specific_deck_list[tooltipItem.index], this.list.filter(e => e.year == this.specific_deck_list[tooltipItem.index].year && e.month == this.specific_deck_list[tooltipItem.index].month && e.week_in_month == this.specific_deck_list[tooltipItem.index].week_in_month))
                            return tooltipItem.value + "RP (Tier: " + tier + ")"
                        }
                    }
                },

                responsive: true,
                maintainAspectRatio: false,

                legend: {
                    display: false // 라벨(범례) 숨기기,
                },

                scales: {
                    // 가로선
                    yAxes: [{
                        display: true,
    
                        gridLines : {
                            display : false
                        }
                    }]
                },
            })
        },

        // 순서 알아내기
        findPositionForScore(list, score) {
            // score를 기준으로 내림차순 정렬
            let sortedList = [...list].sort((a, b) => b.score - a.score);

            // 주어진 점수가 어디에 위치해야 할지 찾기
            for (let i = 0; i < sortedList.length; i++) {
                if (score >= sortedList[i].score) {
                return i + 1; // 해당 위치 반환 (순위는 1부터 시작)
                }
            }

            // 만약 모든 점수보다 낮다면, 리스트의 마지막 순위 반환
            return sortedList.length + 1
        },

        // 티어 계산
        calculateTier(item, list){
            let rp_index = this.findPositionForScore(list, item.score)

            let grade_tier = 5
            let win_tier = 5
            let usage_tier = 5
            let race_tier = 5

            // 비율 티어
            if(this.calculateProbability(rp_index, list.length) <= 6){
                grade_tier = 1
            }
            else if(this.calculateProbability(rp_index, list.length) > 6 && this.calculateProbability(rp_index, list.length) <= 22){
                grade_tier = 2
            }
            else if(this.calculateProbability(rp_index, list.length) > 22 && this.calculateProbability(rp_index, list.length) <= 42){
                grade_tier = 3
            }
            else if(this.calculateProbability(rp_index, list.length) > 42 && this.calculateProbability(rp_index, list.length) <= 69){
                grade_tier = 4
            }
            else if(this.calculateProbability(rp_index, list.length) > 69){
                grade_tier = 5
            }

            // console.log("grade_tier", grade_tier)


            // 승률 티어
            if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 48){
                win_tier = 1
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 46 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 48){
                win_tier = 2
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 42 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 46){
                win_tier = 3
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 39 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 42){
                win_tier = 4
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 39){
                win_tier = 5
            }

            // console.log("win_tier", win_tier)


            // 점유율 티어
            if(this.calculateProbability(item.num_records, this.total_count) >= 5){
                usage_tier = 1
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 1 && this.calculateProbability(item.num_records, this.total_count) < 5){
                usage_tier = 2
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 0.1 && this.calculateProbability(item.num_records, this.total_count) < 1){
                usage_tier = 3
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 0.05 && this.calculateProbability(item.num_records, this.total_count) < 0.1){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.num_records, this.total_count) < 0.05){
                usage_tier = 5
            }

            // console.log("usage_tier", usage_tier)

            // 경기수 티어
            if((item.total_wins + item.total_losses + item.total_ties) >= 40){
                race_tier = 1
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 25 && (item.total_wins + item.total_losses + item.total_ties) < 40){
                race_tier = 2
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 3 && (item.total_wins + item.total_losses + item.total_ties) < 25){
                race_tier = 3
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 1 && (item.total_wins + item.total_losses + item.total_ties) < 3){
                race_tier = 4
            }
            else if((item.total_wins + item.total_losses + item.total_ties) < 1){
                race_tier = 5
            }

            // console.log("race_tier", race_tier)

            if (grade_tier < Math.min(win_tier, usage_tier, race_tier)){
                return Math.min(win_tier, usage_tier, race_tier)
            } else {
                return grade_tier
            }
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },
    }
}
</script>