<script>
import { Line } from 'vue-chartjs'
 
export default {
    extends: Line,

    props: [
        "meta_list",
    ],

    data: () => ({
        list: [],
        label_list: [],
        show_list: [],
        win_list: []
    }),

    watch: {
        async meta_list(){
            if(this.meta_list){
                this.label_list = this.meta_list.map(e => e.month+"월"+e.week_in_month+"째주")
                this.show_list = this.meta_list.map(e => e.show_count)
                this.win_list = this.meta_list.map(e => e.win_count)
            }

            await this.createGraph()
        }
    },

    async mounted(){
        if(this.meta_list){
            this.label_list = this.meta_list.map(e => e.month+"월"+e.week_in_month+"째주")
            this.show_list = this.meta_list.map(e => e.show_count)
            this.win_list = this.meta_list.map(e => e.win_count)
        }

        await this.createGraph()
    },

    methods: {
        // Chart 생성
        async createGraph(){
            this.renderChart({
                labels: this.label_list,
                datasets: [
                    {
                        data: this.show_list,
                        borderColor: "rgba(35, 210, 226, 0.44)",
                        backgroundColor: "rgba(35, 210, 226, 0.0)",
                        pointRadius: 2,
                        lineTension: 0
                    },
                    {
                        data: this.win_list,
                        borderColor: "rgba(35, 210, 126, 0.44)",
                        backgroundColor: "rgba(35, 210, 126, 0.0)",
                        pointRadius: 2,
                        lineTension: 0
                    },
                ]
            },
            {
                responsive: true,
                maintainAspectRatio: false,
                
                legend: {
                    display: false // 라벨(범례) 숨기기
                },

                scales: {
                    // 가로선
                    yAxes: [{
                        display: true,
    
                        gridLines : {
                            display : false
                        }
                    }]
                },
            })
        }
    }
}
</script>