<template>
    <div>
        <v-sheet
            class="ma-2 px-4 py-2 pb-3 rounded-10"
            outlined
        >
            <!-- 상단 -->
            <div
                class="d-flex align-center justify-space-between mb-4"
                :class="$vuetify.breakpoint.mobile? '':'mt-2'"
            >
                <!-- 제목 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- PC -->
                    <p
                        v-if="!$vuetify.breakpoint.mobile"
                        style="font-size:18px; font-weight:bold;"
                        class="my-0"
                    >
                        대회 성적 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                    </p>

                    <!-- Mobile -->
                    <p
                        v-if="$vuetify.breakpoint.mobile"
                        style="font-size:10px; font-weight:bold;"
                        class="mb-2 mt-2"
                    >
                        대회 성적 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                    </p>
                </div>

                <!-- 선택 상단 -->
                <SelectHeader
                    :onlyTerm="false"
                />
            </div>

            <!-- 목록 -->
            <v-sheet
                class="rounded-t-15 d-flex align-center"
                height="32"
                color="#F0F3F5"
            >
                <div
                    style="width:242px; font-size:12px; height:38px;"
                    class="text-center d-flex align-center justify-center"
                >
                    덱
                </div>
                <div
                    class="d-flex align-center"
                    style="width:170px; position:relative;"
                >
                    <div
                        style="width:80px; font-size:12px; height:38px;"
                        class="text-center ml-5 d-flex align-center justify-center"
                    >
                        작성자
                    </div>
                    <div
                        style="width:84px; font-size:12px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        RP
                    </div>
                </div>
            </v-sheet>
            <v-sheet
                v-for="(item, index) in currentPageList" :key="index+'list'"
                height="38"
                class="d-flex align-center ma-0 pa-0"
                style="position:relative; border-bottom:1px solid #C8C8C8; cursor:pointer;"
                @click="$router.push('/deck/read?id='+item.deck_id)"
            >
                <div
                    style="width:82px; font-size:10px; height:38px;"
                    class="text-center d-flex align-center justify-center"
                >
                    <v-img
                        v-for="icon in item.deck_icons.split(',')" :key="icon"
                        width="28"
                        height="28"
                        contain
                        class="mb-1"
                        style="opacity:1"
                        :src="'/upload/pokesprite/'+icon+'.png'"
                    ></v-img>
                </div>
                <div
                    class="pt-2 pr-0 pl-2px text-truncate"
                    style="width:90px; font-size:10px; height:38px; line-height:12px;"
                >
                    {{item.deck_name_kr}}<br>
                    <span class="grey--text">{{item.tournament_name}}</span>
                </div>
                <v-spacer></v-spacer>
                <div
                    class="d-flex align-center"
                    style="width:150px; position:relative;"
                >
                    <p
                        style="font-size:10px; width:60px;"
                        class="mb-0 text-center blue--text mr-2 text-truncate"
                    >
                        {{item.player}}<br/>
                        덱 보기
                    </p>
                    <div
                        style="width:76px; font-size:12px; height:38px; font-weight:bold; line-height:16px;"
                        class="text-center mt-2"
                    >
                        <span v-if="item.player == item.winner">승</span>
                        <span v-else-if="item.player != item.winner">패</span>
                        <span v-else-if="item.winner == -1">무</span>
                        <br/>
                        <span class="grey--text font-weight-regular" style="font-size:10px;">{{new Date(item.date).toLocaleDateString()}}</span>
                    </div>
                </div>
            </v-sheet>

            <!-- 버튼 & 페이지네이션 -->
            <v-pagination
                class="mt-2"
                :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>
        </v-sheet>
    </div>
</template>
<script>
import SelectHeader from './components/SelectHeader'

export default {
    props: ["deck_name"],

    components: {
        SelectHeader
    },

    data: () => ({
        list: [],
        currentPageList: [],

        page: 1,
        pageLength: 1,
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        }
    },

    mounted(){
        // 대회 성적 불러오기
        this.loadRaceRecord()
    },

    methods: {
        // 대회 성적 불러오기
        loadRaceRecord(){
            this.$http.post("/api/league/deck/select/race_record", {
                params: {
                    // deck_name: "Snorlax Stall"
                    deck_name: this.deck_name
                }
            }).then((res) => {
                this.list = res.data.filter(e => e.player == this.$route.query.player)
                this.currentPageList = this.list.slice(0,6)
                this.pageLength = Math.ceil(this.list.length/6)
                if(this.pageLength > 99) this.pageLength = 99
                this.page = 1
            })
        },
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>