<template>
    <div>
        <v-sheet
            class="ma-2 px-4 py-2 pb-3 rounded-10"
            outlined
        >
            <!-- 상단 -->
            <div
                class="d-flex align-center justify-space-between mb-4"
                :class="$vuetify.breakpoint.mobile? '':'mt-2'"
            >
                <!-- 제목 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- PC -->
                    <p
                        v-if="!$vuetify.breakpoint.mobile"
                        style="font-size:18px; font-weight:bold;"
                        class="my-0"
                    >
                        장인 랭킹 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                    </p>

                    <!-- Mobile -->
                    <p
                        v-if="$vuetify.breakpoint.mobile"
                        style="font-size:10px; font-weight:bold;"
                        class="mb-2 mt-2"
                    >
                        장인 랭킹 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                    </p>
                </div>

                <!-- 선택 상단 -->
                <SelectHeader
                    :onlyTerm="false"
                />
            </div>

            <!-- 목록 -->
            <div>
                <!-- 목록 상단 -->
                <v-sheet
                    class="rounded-t-15 d-flex align-center"
                    :height="$vuetify.breakpoint.mobile? '32' : '40'"
                    color="#F0F3F5"
                >
                    <!-- # -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '10%' : '10%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        #
                    </v-sheet>

                    <!-- 국가 -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '10%' : '10%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        국가
                    </v-sheet>

                    <!-- 플레이어 -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '40%' : '40%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        플레이어
                    </v-sheet>

                    <!-- RP -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '20%' : '20%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        RP
                    </v-sheet>

                    <!-- 덱 -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '20%' : '20%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        덱
                    </v-sheet>
                </v-sheet>

                <!-- 목록 데이터 -->
                <v-sheet
                    v-for="(item, index) in currentPageList" :key="index"
                    :height="$vuetify.breakpoint.mobile? 38 : 54"
                    class="d-flex align-center ma-0 pa-0"
                    style="position:relative; border-bottom:1px solid #dadada; cursor:pointer;"
                >
                    <!-- # -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '10%' : '10%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        {{index + 1}}
                    </v-sheet>

                    <!-- 국가 -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '10%' : '10%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                    >
                        <div>
                            <v-img
                                :width="$vuetify.breakpoint.mobile? 20 : 28"
                                :height="$vuetify.breakpoint.mobile? 20 : 28"
                                :src="'/upload/flags/'+item.country?.toLowerCase()+'.svg'"
                            ></v-img>
                        </div>
                    </v-sheet>

                    <!-- 플레이어 -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '40%' : '40%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;':'font-size:14px;'"
                        class="d-flex align-center justify-center"
                        color="transparent"
                        style="cursor:pointer;"
                        @click="goToLink('/league/player/detail?player='+item.player)"
                    >
                        {{item.player}}
                    </v-sheet>

                    <!-- 작성자 -->
                    <v-sheet
                        :width="$vuetify.breakpoint.mobile? '20%' : '20%'"
                        :style="$vuetify.breakpoint.mobile? 'font-size:10px; line-height:12px;' : 'font-size:12px; line-height:14px; margin-top:2px;'"
                        class="text-center text-truncate px-1"
                    >
                        {{item.player}}<br/>
                        <span class="grey--text" style="font-size:8px; font-weight:300;">{{new Date(item.created).toLocaleDateString()}}</span>
                    </v-sheet>

                    <!-- 덱 보기 -->
                    <v-sheet
                        style="font-weight:bold;"
                        :style="$vuetify.breakpoint.mobile? 'font-size:12px;' : 'font-size:14px;'"
                        :width="$vuetify.breakpoint.mobile? '20%' : '20%'"
                        class="d-flex align-center justify-center"
                        :color="item.deck_id == null? 'grey--text text--lighten-1' : 'icu_purple--text'"
                        @click="goToLink('/deck/read?id='+item.deck_id)"
                    >
                        덱 보기
                    </v-sheet>
                </v-sheet>
            </div>

            <!-- 버튼 & 페이지네이션 -->
            <v-pagination
                class="mt-2"
                :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>
        </v-sheet>
    </div>
</template>
<script>
import SelectHeader from './components/SelectHeader'

export default {
    components: {
        SelectHeader
    },

    data: () => ({
        list: [],
        currentPageList: [],

        page: 1,
        pageLength: 1
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        }
    },

    mounted(){
        // 장인 랭킹 불러오기
        this.loadPlayerRanking()
    },

    methods: {
        // 장인 랭킹 불러오기
        loadPlayerRanking(){
            this.$http.post("/api/league/deck/select/player_ranking", {
                params: {
                    // deck_name: "Snorlax Stall"
                    deck_name: this.$route.query.deck_name
                }
            }).then((res) => {
                // console.log("player_ranking", res)
                this.list = res.data
                this.currentPageList = res.data.slice(0,6)
                this.pageLength = Math.ceil(res.data.length/6)
                if(this.pageLength > 99) this.pageLength = 99
                this.page = 1
            })
        },

        goToLink(link){
            window.open(link, '_blank')
        }
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>