<template>
    <div>
        <!-- 제목 & 기간(Global) -->
        <div
            class="d-flex align-center px-5 pt-3 pb-3 mobile"
        >
            <!-- 제목 -->
            <p
                style="font-size:16px; font-weight:bold;"
                class="mb-0"
            >
                티어 덱
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <v-spacer></v-spacer>

            <!-- 기간 -->
            <v-select
                height="33"
                style="width:108px;"
                class="rounded-10 font-weight-bold shrink"
                dense
                outlined
                hide-details
                :items="select_list.term"
                v-model="keyword.term"
                @change="term_updated()"
            >
                <template v-slot:selection="{ item }">
                    <span
                        class="text-truncate mt-1"
                        style="display:inline-block; width:80px; margin-bottom:-26px;"
                    >
                        {{item}}
                    </span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <v-btn
                depressed
                dark
                class="rounded-10 ml-2"
                style="font-size:12px;"
                color="primary"
                width="46"
                height="28"
                @click="$router.push('/league/deck/list')"
            >
                목록
            </v-btn>
        </div>

        <!-- 덱 정보 상단 -->
        <v-sheet
            class="py-2 px-3 d-flex"
            style="position:relative;"
        >
            <v-sheet
                width="156"
                height="90"
                style="position:relative;"
            >
                <!-- 메인포켓몬 카드 -->
                <v-sheet
                    width="156"
                    height="90"
                    class="rounded-5"
                    style="position:relative; overflow:hidden;"
                >
                    <v-sheet
                        width="190"
                        height="112"
                        style="overflow:hidden; margin-left:-27px;"
                    >
                        <v-img
                            width="190"
                            height="112"
                            position="center 20%"
                            :src="deck_main_image==null? '/upload/card_en_image/default.png':'/upload/card_en_image/'+deck_main_image"
                            style="position:relative; overflow:visible;"
                        >
                        </v-img>
                    </v-sheet>

                    <!-- POKESPRITE 배경 -->
                    <v-sheet
                        style="background: rgba(255, 255, 255, 0.5); position:absolute; bottom:4px; left:4px;"
                        class="rounded-lg d-flex pr-1"
                    >
                        <!-- POKESPRITE 목록 -->
                        <div class="d-flex">
                            <v-img
                                v-for="item in deck_icons" :key="item"
                                width="32"
                                class="mb-1"
                                style="opacity:1"
                                :src="'/upload/pokesprite/'+item+'.png'"
                            ></v-img>
                        </div>
                    </v-sheet>
                </v-sheet>
                <!-- 티어 -->
                <div
                    class="diamond-box"
                    style="position:absolute; bottom:10px; right:-12px;"
                    :style="calculateTierColor(this.tier)"
                >
                    <div
                        class="diamond-content d-flex justify-center align-center"
                        style="color:white; font-size:10px;"
                    >
                        {{tier}}
                    </div>
                </div>
            </v-sheet>

            <v-sheet
                class="ml-6 py-1"
            >
                <p
                    class="mb-0 font-weight-bold"
                    style="font-size:12px;"
                >
                    {{deck_name_kr}}
                </p>
                <p
                    style="color:#C8C9CF; font-size:9px; margin-bottom:4px;"
                >
                    {{deck_name_en}}
                </p>
                <p
                    style="color:#C8C9CF; font-size:9px; margin-bottom:4px;"
                >
                    {{tier}}티어
                    <span style="color:#C8C9CF; font-size:9px; margin-left:20px;">{{top_rate}}위</span>
                    <span style="color:#C8C9CF; font-size:9px; margin-left:32px;">{{append_type}}</span>
                </p>
                <div
                    class="d-flex justify-space-between"
                    style="width:140px;"
                >
                    <div>
                        <p
                            style="font-size:10px; margin-bottom:4px;"
                        >
                            승률
                        </p>
                        <p
                            style="font-size:9px; font-weight:bold; margin-bottom:0px;"
                        >
                            {{win_rate}}%
                        </p>
                    </div>
                    <v-divider vertical></v-divider>
                    <div>
                        <p
                            style="font-size:10px; margin-bottom:4px;"
                        >
                            사용비율
                        </p>
                        <p
                            style="font-size:9px; font-weight:bold; margin-bottom:0px;"
                        >
                            {{usage_rate}}%
                        </p>
                    </div>
                    <v-divider vertical></v-divider>
                    <div>
                        <p
                            style="font-size:10px; margin-bottom:4px;"
                        >
                            최고성적
                        </p>
                        <p
                            style="font-size:9px; font-weight:bold; margin-bottom:0px;"
                        >
                            {{top_rate}}위/{{top_total}}명
                        </p>
                    </div>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- 티어 추이 -->
        <TierChart />

        <!-- 상대 전적 -->
        <VsRecord />

        <!-- 채용 카드 -->
        <!-- <CardData /> -->

        <!-- 메타 추이 -->
        <MetaChart />

        <!-- 대회 성적 -->
        <RaceRecord />

        <!-- 장인 랭킹 -->
        <PlayerRanking />

        <!-- 덱 레시피 -->
        <DeckRecipe />
    </div>
</template>
<script>
import TierChart from "./components/TierChart"
// import CardData from "./components/CardData"
import VsRecord from "./components/VsRecord"
import MetaChart from "./components/MetaChart"
import RaceRecord from "./components/RaceRecord"
import PlayerRanking from "./components/PlayerRanking"
import DeckRecipe from "./components/DeckRecipe"

export default {
    components: {
        TierChart,
        // CardData,
        VsRecord,
        MetaChart,
        RaceRecord,
        PlayerRanking,
        DeckRecipe
    },

    data: () => ({
        select_list: {
            term: [
                "최근 1개월",
                "최근 3개월",
                "최근 6개월",
                "최근 9개월",
                "최근 12개월"
            ]
        },

        deck_icons: [],
        deck_name_kr: "",
        deck_name_en: "",
        deck_main_image: "",
        tier: null,

        append_type: "", // EX, VMAX

        win_rate: 0,
        usage_rate: 0,
        top_rate: 0,
        top_total: 0,

        keyword: {
            term: "최근 3개월",
        },
    }),

    async mounted(){
        // 덱 기존 리그 정보 불러오기
        await this.load()

        // 최고성적 불러오기
        this.loadBest()

        // 검색 (티어, 승률, 사용비율)
        this.search()
    },

    methods: {
        // 덱 기존 리그 정보 불러오기
        load(){
            this.$http.post("/api/league/deck/select/basic", {
                params: {
                    deck_name: this.$route.query.deck_name
                }
            }).then((res) => {
                // console.log("loadDeck", res)
                this.deck_icons = res.data[0].deck_icons.split(',')
                this.deck_name_kr = res.data[0].deck_name_kr
                this.deck_name_en = res.data[0].deck_name_en
                this.deck_main_image = res.data.filter(e => e.main_pokemon_image != null)[0].main_pokemon_image
                // console.log(this.deck_main_image)
            })
        },

        // 최고성적 불러오기
        loadBest(){
            this.$http.post("/api/league/deck/select/best", {
                params: {
                    deck_name: this.$route.query.deck_name
                }
            }).then((res) => {
                // console.log(res)
                this.top_rate = res.data[0].placing
                this.top_total = res.data[0].players
            })
        },

        // 검색 (티어, 승률, 사용비율)
        search(){
            this.$http.post("/api/league/deck/select/searchForSummary", {
                params: {
                    keyword: this.keyword
                }
            }).then(async(res) => {
                // console.log(res)
                this.list = res.data.slice(0,100)
                this.total_count = this.list.reduce((sum, item) => sum + item.num_records, 0)

                await this.list.forEach(async (e, index) => {
                    e.rp_index = index + 1
                    e.tier = await this.calculateTier(e)

                    if(e.deck_name_en == this.deck_name_en)
                    {
                        this.tier = e.tier
                    }
                })
            })
        },

        // 티어 계산
        calculateTier(item){
            let grade_tier = 5
            let win_tier = 5
            let usage_tier = 5
            let race_tier = 5

            // 비율 티어
            if(this.calculateProbability(item.rp_index, this.list.length) <= 6){
                grade_tier = 1
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 6 && this.calculateProbability(item.rp_index, this.list.length) <= 22){
                grade_tier = 2
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 22 && this.calculateProbability(item.rp_index, this.list.length) <= 42){
                grade_tier = 3
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 42 && this.calculateProbability(item.rp_index, this.list.length) <= 69){
                grade_tier = 4
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 69){
                grade_tier = 5
            }

            // console.log("grade_tier", grade_tier)


            // 승률 티어
            if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 48){
                win_tier = 1
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 46 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 48){
                win_tier = 2
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 42 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 46){
                win_tier = 3
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 39 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 42){
                win_tier = 4
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 39){
                win_tier = 5
            }

            // 승률 가져오기
            if(item.deck_name_en == this.deck_name_en)
            {
                this.win_rate = this.calculateProbability(item.total_wins, item.total_wins + item.total_losses)
            }

            // console.log("win_tier", win_tier)


            // 점유율 티어
            if(this.calculateProbability(item.num_records, this.total_count) >= 5){
                usage_tier = 1
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 1 && this.calculateProbability(item.num_records, this.total_count) < 5){
                usage_tier = 2
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 0.1 && this.calculateProbability(item.num_records, this.total_count) < 1){
                usage_tier = 3
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 0.05 && this.calculateProbability(item.num_records, this.total_count) < 0.1){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.num_records, this.total_count) < 0.05){
                usage_tier = 5
            }

            // 사용비율 가져오기
            if(item.deck_name_en == this.deck_name_en)
            {
                this.usage_rate = this.calculateProbability(item.num_records, this.total_count)
            }

            // console.log("usage_tier", usage_tier)

            // 경기수 티어
            if((item.total_wins + item.total_losses + item.total_ties) >= 500){
                race_tier = 1
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 300 && (item.total_wins + item.total_losses + item.total_ties) < 500){
                race_tier = 2
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 30 && (item.total_wins + item.total_losses + item.total_ties) < 300){
                race_tier = 3
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 8 && (item.total_wins + item.total_losses + item.total_ties) < 30){
                race_tier = 4
            }
            else if((item.total_wins + item.total_losses + item.total_ties) < 8){
                race_tier = 5
            }

            // console.log("race_tier", race_tier)

            if (grade_tier < Math.min(win_tier, usage_tier, race_tier)){
                return Math.min(win_tier, usage_tier, race_tier)
            } else {
                return grade_tier
            }
        },

        // 티어 색상 계산
        calculateTierColor(tier){
            if(tier == 1) return "background:#843CFF;"
            else if(tier == 2) return "background:#0093FF;"
            else if(tier == 3) return "background:#00BBA3;"
            else if(tier == 4) return "background:#FFB900;"
            else if(tier == 5) return "background:#B3B3C2;"
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 28px;
    height: 28px;
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 28px;
    height: 28px;
    transform: rotate(-45deg);
    display: block;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>