<template>
    <div>
        <v-sheet
            class="ma-2 px-4 py-2 pb-3 rounded-10"
            outlined
        >
            <!-- 상단 -->
            <div
                class="d-flex align-center justify-space-between mb-4"
                :class="$vuetify.breakpoint.mobile? '':'mt-2'"
            >
                <!-- 제목 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- PC -->
                    <p
                        v-if="!$vuetify.breakpoint.mobile"
                        style="font-size:18px; font-weight:bold;"
                        class="my-0"
                    >
                        상대 전적 <v-icon class="pb-2px">mdi-chevron-right</v-icon>
                    </p>

                    <!-- Mobile -->
                    <p
                        v-if="$vuetify.breakpoint.mobile"
                        style="font-size:10px; font-weight:bold;"
                        class="mb-2 mt-2"
                    >
                        상대 전적 <v-icon small class="pb-2px">mdi-chevron-right</v-icon>
                    </p>
                </div>

                <!-- 선택 상단 -->
                <SelectHeader
                    :onlyTerm="false"
                />
            </div>

            <!-- 목록 -->
            <v-sheet
                class="rounded-t-15 d-flex align-center"
                height="32"
                color="#F0F3F5"
            >
                <div
                    style="width:232px; font-size:12px; height:38px;"
                    class="text-center d-flex align-center justify-center"
                >
                    덱
                </div>
                <div
                    class="d-flex align-center"
                    style="width:154px; position:relative;"
                >
                    <div
                        style="width:85px; font-size:12px; height:38px;"
                        class="text-center ml-5 d-flex align-center justify-center ml-6"
                    >
                        전적
                    </div>
                    <div
                        style="width:148px; font-size:12px; height:38px;"
                        class="text-center d-flex align-center justify-center"
                    >
                        승률
                    </div>
                </div>
            </v-sheet>
            <v-sheet
                v-for="(item, index) in currentPageList" :key="index"
                height="38"
                class="d-flex align-center ma-0 pa-0"
                style="position:relative; border-bottom:1px solid #C8C8C8;"
            >
                <div
                    style="width:62px; font-size:10px; height:38px;"
                    class="text-center d-flex align-center justify-center"
                >
                    <v-img
                        width="28"
                        height="28"
                        contain
                        class="mb-1"
                        style="opacity:1"
                        :src="'/upload/pokesprite/'+item.opponent_deck_name_en?.split(' ')[0]"
                    ></v-img>
                </div>
                <div
                    class="pt-2 pr-3 pl-2px text-truncate"
                    style="width:170px; font-size:10px; height:38px; line-height:12px;"
                >
                    {{item.opponent_deck_name_kr}}<br>
                    <span class="grey--text">{{item.tournament_name}}</span>
                </div>
                <div
                    class="d-flex align-center pr-2"
                    style="width:174px; position:relative;"
                >
                    <p
                        style="font-size:10px;"
                        class="mb-0 text-center"
                    >
                        {{item.win_count}}승 {{item.opponent_win_count}}패 {{item.ties_count}}무 ({{item.win_count + item.opponent_win_count + item.ties_count}})
                    </p>
                    <div
                        style="width:76px; font-size:12px; height:38px; font-weight:bold; line-height:16px;"
                        class="text-center mt-5"
                        :style="calculateWinRate(item.win_count, item.opponent_win_count) > 50? 'color:#F8468D':'color:#615DFA'"
                    >
                        {{calculateWinRate(item.win_count, item.opponent_win_count)}}%
                    </div>
                </div>
            </v-sheet>

            <!-- 버튼 & 페이지네이션 -->
            <v-pagination
                class="mt-2"
                :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
                color="primary"
                v-model="page"
                :length="pageLength"
                :total-visible="7"
            ></v-pagination>
        </v-sheet>
    </div>
</template>
<script>
import SelectHeader from './components/SelectHeader'

export default {
    components: {
        SelectHeader
    },

    data: () => ({
        list: [],
        currentPageList: [],

        page: 1,
        pageLength: 1
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        }
    },

    mounted(){
        // 상대 전적 목록 불러오기
        this.loadVsRecord()
    },

    methods: {
        // 상대 전적 목록 불러오기
        loadVsRecord(){
            this.$http.post("/api/league/deck/select/vs_record", {
                params: {
                    // deck_name: "Snorlax Stall"
                    deck_name: this.$route.query.deck_name
                }
            }).then((res) => {
                // console.log("vs_record", res)
                this.list = res.data
                this.currentPageList = res.data.slice(0,6)
                this.pageLength = Math.ceil(res.data.length/6)
                if(this.pageLength > 99) this.pageLength = 99
                this.page = 1
            })
        },

        calculateWinRate(winCount, opponentWinCount) {
            // 전체 게임 횟수가 0인 경우, 승률을 계산할 수 없으므로 0을 반환
            if (winCount + opponentWinCount === 0) {
                return 0
            }

            // 승률 계산
            return ((winCount / (winCount + opponentWinCount)) * 100).toFixed(2)
        }
    }
}
</script>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>