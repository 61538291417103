<template>
    <div>
        <!-- 제목 & 상세검색 -->
        <div
            class="d-flex align-center justify-space-between px-5 pt-3 pb-3"
        >
            <!-- 제목 -->
            <p
                style="font-size:16px; font-weight:bold;"
                class="mb-0"
            >
                티어 덱
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <v-btn
                depressed
                dark
                class="rounded-10"
                style="font-size:12px;"
                color="primary"
                width="90"
                height="28"
                @click="detail_switch = !detail_switch"
            >
                <v-icon small class="mr-1">mdi-magnify</v-icon>
                상세검색
            </v-btn>
        </div>

        <!-- 기간 & 메인포켓몬 & 지역 -->
        <div
            class="d-flex px-4 pt-2 white mobile"
        >
            <!-- 기간 -->
            <v-select
                height="33"
                style="flex: 1; width:108px;"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="기간"
                placeholder="기간"
                persistent-placeholder
                :items="select_list.term"
                v-model="keyword.term"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block; width:80px; margin-bottom:-26px;">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 메인포켓몬 -->
            <v-select
                height="33"
                style="flex: 1; width:108px;"
                class="rounded-10 font-weight-bold mx-1"
                dense
                outlined
                hide-details
                label="메인포켓몬"
                placeholder="메인포켓몬"
                persistent-placeholder
                item-text="name"
                item-value="value"
                :items="select_list.main_pokemon"
                v-model="keyword.main_pokemon"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block; width:80px; margin-bottom:-26px;">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="mr-1 mt-1" color="#424361" style="margin-bottom:-22px;">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 지역 -->
            <v-select
                height="33"
                style="flex: 1"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="지역"
                placeholder="지역"
                persistent-placeholder
                :items="select_list.local"
                v-model="keyword.local"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 제품명 시즌 (상세) -->
        <div
            v-if="detail_switch"
            class="px-4 pt-6px white mobile"
        >
            <!-- 제품명 시즌 -->
            <v-select
                height="33"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="제품명 시즌"
                placeholder="제품명 시즌"
                persistent-placeholder
                :items="select_list.season"
                v-model="keyword.season"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 레귤레이션 & 지역 (상세) -->
        <div
            v-if="detail_switch"
            class="d-flex px-4 pt-6px white mobile"
        >
            <!-- 레귤레이션 -->
            <v-select
                height="33"
                style="flex: 1"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="레귤레이션"
                placeholder="레귤레이션"
                persistent-placeholder
                :items="select_list.regulation"
                v-model="keyword.regulation"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 지역 -->
            <v-select
                height="33"
                style="flex: 1"
                class="rounded-10 font-weight-bold ml-1"
                dense
                outlined
                hide-details
                label="지역"
                placeholder="지역"
                persistent-placeholder
                :items="select_list.local"
                v-model="keyword.local"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 덱에 포함된 카드 이름 (상세) -->
        <div
            v-if="detail_switch"
            class="px-4 pt-6px white mobile"
        >
            <!-- 덱에 포함된 카드 이름 (상세) -->
            <v-text-field
                height="33"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="덱에 포함된 카드 이름"
                placeholder="덱에 포함된 카드 이름"
                persistent-placeholder
                v-model="keyword.card_name"
                @change="search()"
            >
                <template v-slot:append>
                    <v-icon small class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </div>

        <div
            class="d-flex px-4 pt-6px mobile"
        >
            <div class="d-flex" style="position:relative; width:100%;">
                <!-- 카드타입 -->
                <v-select
                    height="34"
                    class="rounded-10"
                    dense
                    readonly
                    outlined
                    hide-details
                    placeholder=""
                    persistent-placeholder
                    :menu-props="{ offsetY: true }"
                    @change="search()"
                >
                    <template v-slot:append>
                        <v-icon class="mr-1" size="20" color="#424361"></v-icon>
                    </template>
                </v-select>

                <v-chip-group
                    style="position:absolute; width:100%; bottom:0px; left:2px;"
                    v-model="keyword.types"
                    multiple
                    column
                >
                    <div class="pl-5px d-flex" style="border: none;">
                        <v-chip
                            class="ml-1 pl-1px pr-1px mx-0"
                            style="height:22px !important;"
                            v-for="item in types" :key="item"
                            :value="item"
                        >
                            <v-img
                                width="18"
                                height="18"
                                :src="require(`@/assets/card/type/${item}.png`)"
                            ></v-img>
                        </v-chip>
                    </div>
                </v-chip-group>
            </div>
        </div>

        <!-- 기간 & 메인포켓몬 묶어보기 & 지역 -->
        <div
            class="d-flex px-4 pt-1 pb-2 mobile"
        >
            <!-- 서브타입 -->
            <v-select
                height="33"
                style="flex: 1"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                persistent-placeholder
                :items="select_list.subtypes"
                v-model="keyword.subtypes"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 메인포켓몬 묶어보기 -->
            <v-sheet
                width="108"
                class="rounded-10 mx-1"
                style="border:1px solid #e0e0eb;"
            >
                <v-checkbox
                    class="mt-0 ml-1"
                    dense
                    hide-details
                    label="메인포켓몬 묶어보기"
                    v-model="keyword.is_group"
                    @change="search()"
                ></v-checkbox>
            </v-sheet>

            <v-select
                height="33"
                style="flex: 1"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                persistent-placeholder
                :items="select_list.range"
                v-model="keyword.range"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 목록 상단 -->
        <v-sheet
            height="32"
            color="#F0F3F5"
            class="pt-6px d-flex"
        >
            <div
                style="width:48px; font-size:12px;"
                class="text-center"
            >
                #
            </div>
            <div
                style="width:184px; font-size:12px;"
                class="text-center"
            >
                덱
            </div>
            <div
                style="width:54px; font-size:12px;"
                class="text-center"
            >
                티어
            </div>
            <div
                style="width:106px; font-size:12px;"
                class="text-center"
            >
                점유율
            </div>
            <div
                style="width:106px; font-size:12px;"
                class="text-center"
            >
                RP
            </div>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            v-for="(item, index) in list" :key="item.id"
            height="38"
            class="d-flex align-center ma-0 pa-0"
            style="position:relative; cursor:pointer;"
            @click="$router.push('/league/deck/detail?deck_name='+item.deck_name_en)"
        >
            <div
                style="width:48px; font-size:10px; height:38px; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                {{index + 1}}
            </div>
            <div
                class="d-flex justify-space-between align-center pr-3"
                style="width:184px; font-size:10px; height:38px; border-bottom:1px solid #C8C8C8;"
            >
                <div
                    class="d-flex justify-start"
                >
                    <v-img
                        v-for="(image, index) in item.deck_icons?.split(',')" :key="item.id+'image'+index"
                        height="40"
                        width="40"
                        contain
                        :src="'/upload/pokesprite/'+image+'.png'"
                    ></v-img>
                </div>
                <p
                    style="font-size:8px;"
                    class="mb-0 grey--text text--lighten-1 text-end"
                >
                    {{item.deck_name_kr==null? item.deck_name : item.deck_name_kr}}
                </p>
            </div>
            <div
                style="width:54px; font-size:12px; background:#FAFAFA; height:38px; border-bottom:1px solid #C8C8C8;"
                class="d-flex align-center justify-center"
            >
                <div
                    class="diamond-box"
                    :style="calculateTierColor(calculateTier(item))"
                >
                    <div
                        class="diamond-content d-flex justify-center align-center"
                        style="color:white; font-size:10px;"
                    >
                        {{calculateTier(item)}}
                    </div>
                </div>
            </div>
            <div
                class="d-flex"
                style="width:154px; position:relative;"
            >
                <div
                    style="width:78px; font-size:12px; height:38px; border-bottom:1px solid #C8C8C8;"
                    class="text-center d-flex align-center justify-center"
                >
                    {{calculateProbability(item.num_records, total_count)}}%
                </div>
                <div
                    style="width:76px; font-size:12px; height:38px; border-bottom:1px solid #C8C8C8;"
                    class="text-center d-flex align-center justify-center"
                >
                    {{item.total_wins * 2 - item.total_losses - item.total_ties}}RP
                </div>

                <v-progress-linear
                    style="position:absolute; width:100%; bottom:0px; right:0px; z-index:5;"
                    height="1"
                    color="#F8468D"
                    background-color="grey lighten-3"
                    :value="calculateProbability(item.num_records, total_count/4)"
                ></v-progress-linear>
            </div>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            term: [
                "전체",
                "최근 1개월",
                "최근 3개월",
                "최근 6개월",
                "최근 9개월",
                "최근 12개월",
            ],
            main_pokemon: ["전체"],
            local: [
                "전체",
                "US",
                "CL",
                "CA",
                "AR",
                "BR",
                "KR",
                "JP",
                "MX",
                "PR",
                "EE",
                "ID",
                "AU",
                "CN",
                "IN",
                "PE",
                "IE",
                "GB",
                "GR",
                "GT",
                "PH",
                "TH",
                "HK",
                "IT",
                "TW",
                "SV",
                "SG",
                "NZ",
                "DE",
                "PT",
                "FR",
                "UA",
                "BE",
                "PL",
                "ZA",
                "CH",
                "ES",
                "UY",
                "NL",
                "LT",
                "RU",
                "SE",
                "IL",
                "EC",
                "MY",
                "AE",
                "CR",
                "SK",
                "VE",
                "TT",
                "CZ",
                "PY",
                "BO",
                "HR",
                "CO",
                "FI",
                "RO",
                "KW",
                "RS",
                "BG"
            ],
            regulation: ["전체"],
            subtypes: ["전체"],
            range: ["전체"],
            season: ["전체"]
        },

        // 타입이름
        types: [
            "Grass",
            "Fire",
            "Water",
            "Lightning",
            "Psychic",
            "Fighting",
            "Darkness",
            "Metal",
            "Fairy",
            "Dragon",
            "Colorless",
        ],

        keyword: {
            term: "전체",
            main_pokemon: "전체",
            local: "전체",
            subtypes: "전체",
            range: "전체",
            regulation: "전체",
            types: [],
            season: "전체",
            card_name: "",
            is_group: false
        },

        detail_switch: false,

        list: [],
        total_count: 0
    }),

    mounted(){
        // 메인포켓몬 선택목록 가져오기
        this.loadMainPokemon()

        // 검색
        this.search()
    },

    methods: {
        // 메인포켓몬 선택목록 가져오기
        loadMainPokemon(){
            this.$http.post("/api/league/deck/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                this.select_list.main_pokemon = []

                res.data.forEach(item => {
                    if (item.deck_name_kr) {
                        let names = item.deck_name_kr.split(' ')
                        names.forEach(name => {
                            if (!this.select_list.main_pokemon.includes(name))
                            {
                                this.select_list.main_pokemon.push(name)
                            }
                        })
                    }
                })
            })
        },

        // 검색
        search(){
            this.$http.post("/api/league/deck/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then(async(res) => {
                // console.log(res)
                this.list = res.data.slice(0,100)
                this.total_count = this.list.reduce((sum, item) => sum + item.num_records, 0)
                console.log(this.list)

                await this.list.forEach((e, index) => {
                    e.rp_index = index + 1
                    e.tier = this.calculateTier(e)
                })

                this.list = this.list.sort((a, b) => {
                    // 먼저 tier에 따라 정렬
                    if (a.tier !== b.tier) {
                        return a.tier - b.tier
                    }
                    // tier가 같다면 rp에 따라 내림차순 정렬
                    if (a.rp !== b.rp) {
                        return b.rp - a.rp
                    }
                    // tier와 rp가 모두 같다면 num_records에 따라 내림차순 정렬
                    return b.num_records - a.num_records
                })
            })
        },

        // 점유율 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        },

        // 티어 계산
        calculateTier(item){
            let grade_tier = 5
            let win_tier = 5
            let usage_tier = 5
            let race_tier = 5

            // 비율 티어
            if(this.calculateProbability(item.rp_index, this.list.length) <= 6){
                grade_tier = 1
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 6 && this.calculateProbability(item.rp_index, this.list.length) <= 22){
                grade_tier = 2
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 22 && this.calculateProbability(item.rp_index, this.list.length) <= 42){
                grade_tier = 3
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 42 && this.calculateProbability(item.rp_index, this.list.length) <= 69){
                grade_tier = 4
            }
            else if(this.calculateProbability(item.rp_index, this.list.length) > 69){
                grade_tier = 5
            }

            // console.log("grade_tier", grade_tier)


            // 승률 티어
            if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 48){
                win_tier = 1
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 46 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 48){
                win_tier = 2
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 42 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 46){
                win_tier = 3
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) >= 39 && this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 42){
                win_tier = 4
            }
            else if(this.calculateProbability(item.total_wins, item.total_wins + item.total_losses) < 39){
                win_tier = 5
            }

            // console.log("win_tier", win_tier)


            // 점유율 티어
            if(this.calculateProbability(item.num_records, this.total_count) >= 5){
                usage_tier = 1
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 1 && this.calculateProbability(item.num_records, this.total_count) < 5){
                usage_tier = 2
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 0.1 && this.calculateProbability(item.num_records, this.total_count) < 1){
                usage_tier = 3
            }
            else if(this.calculateProbability(item.num_records, this.total_count) >= 0.05 && this.calculateProbability(item.num_records, this.total_count) < 0.1){
                usage_tier = 4
            }
            else if(this.calculateProbability(item.num_records, this.total_count) < 0.05){
                usage_tier = 5
            }

            // console.log("usage_tier", usage_tier)

            // 경기수 티어
            if((item.total_wins + item.total_losses + item.total_ties) >= 500){
                race_tier = 1
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 300 && (item.total_wins + item.total_losses + item.total_ties) < 500){
                race_tier = 2
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 30 && (item.total_wins + item.total_losses + item.total_ties) < 300){
                race_tier = 3
            }
            else if((item.total_wins + item.total_losses + item.total_ties) >= 8 && (item.total_wins + item.total_losses + item.total_ties) < 30){
                race_tier = 4
            }
            else if((item.total_wins + item.total_losses + item.total_ties) < 8){
                race_tier = 5
            }

            // console.log("race_tier", race_tier)

            if (grade_tier < Math.min(win_tier, usage_tier, race_tier)){
                return Math.min(win_tier, usage_tier, race_tier)
            } else {
                return grade_tier
            }
        },

        // 티어 색상 계산
        calculateTierColor(tier){
            if(tier == 1) return "background:#843CFF;"
            else if(tier == 2) return "background:#0093FF;"
            else if(tier == 3) return "background:#00BBA3;"
            else if(tier == 4) return "background:#FFB900;"
            else if(tier == 5) return "background:#B3B3C2;"
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 20px;
    height: 20px;
    background-color: blue; /* Change the color as needed */
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    display: block;
}
</style>