<template>
    <div>
        <!-- 제목 & 기간(Global) -->
        <div
            class="d-flex align-center pl-5 pr-4 pt-3 pb-3 mobile"
        >
            <!-- 제목 -->
            <p
                style="font-size:16px; font-weight:bold;"
                class="mb-0"
            >
                대회 목록
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                class="ml-2"
                indeterminate
                color="primary"
                size="18"
                width="2"
            ></v-progress-circular>

            <v-spacer></v-spacer>

            <!-- 검색 -->
            <v-select
                height="33"
                style="margin-bottom:-2px; width:120px; background:white;"
                class="rounded-10 font-weight-bold shrink"
                dense
                outlined
                hide-details
                persistent-placeholder
                :items="select_list.range"
                v-model="keyword.range"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 기간 & 대회 구분 & 지역 -->
        <div
            class="d-flex px-4 py-3 mb-2 white mobile"
        >
            <!-- 기간 -->
            <v-select
                height="33"
                style="flex: 1; width:108px;"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="기간"
                placeholder="기간"
                persistent-placeholder
                :items="select_list.term"
                v-model="keyword.term"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block; width:80px; margin-bottom:-26px;">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 대회 구분 -->
            <v-select
                height="33"
                style="flex: 1; width:108px;"
                class="rounded-10 font-weight-bold mx-1"
                dense
                outlined
                hide-details
                label="대회 구분"
                placeholder="대회 구분"
                persistent-placeholder
                item-text="name"
                item-value="value"
                :items="select_list.tournament_type"
                v-model="keyword.tournament_type"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="text-truncate mt-1" style="display:inline-block; width:80px; margin-bottom:-26px;">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="mr-1 mt-1" color="#424361" style="margin-bottom:-22px;">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 지역 -->
            <v-select
                height="33"
                style="flex: 1"
                class="rounded-10 font-weight-bold"
                dense
                outlined
                hide-details
                label="지역"
                placeholder="지역"
                persistent-placeholder
                :items="select_list.country"
                v-model="keyword.country"
                @change="search()"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2 pb-1px">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 목록 상단 -->
        <v-sheet
            height="32"
            color="#F0F3F5"
            class="pt-6px d-flex"
        >
            <div
                style="width:66px; font-size:12px;"
                class="text-center"
            >
                날짜
            </div>
            <div
                style="width:52px; font-size:12px;"
                class="text-center"
            >
                국가
            </div>
            <div
                style="width:180px; font-size:12px;"
                class="text-center"
            >
                대회
            </div>
            <div
                style="width:66px; font-size:12px;"
                class="text-center"
            >
                대회정보
            </div>
        </v-sheet>

        <!-- 목록 -->
        <v-sheet
            v-for="(item, index) in currentPageList" :key="item.id"
            height="38"
            class="d-flex align-center ma-0 pa-0"
            style="position:relative; cursor:pointer;"
            @click="$router.push('/league/player/detail?player='+item.player)"
        >
            <!-- 날짜 -->
            <div
                style="width:66px; font-size:10px; height:38px; border-bottom:1px solid #C8C8C8; letter-spacing:-0.5px;"
                class="text-center d-flex align-center justify-center"
            >
                {{new Date(item.date).toLocaleDateString().slice(2).slice(0, -1)}}
            </div>

            <!-- 국가 -->
            <div
                style="width:52px; font-size:10px; height:38px; border-bottom:1px solid #C8C8C8;"
                class="text-center d-flex align-center justify-center"
            >
                <div>
                    <v-img
                        class="rounded-5"
                        width="24"
                        height="21"
                        :src="'/upload/flags/'+item.country?.toLowerCase()+'.svg'"
                    ></v-img>
                </div>
            </div>

            <!-- 대회 -->
            <div
                style="width:180px; font-size:10px; height:38px; border-bottom:1px solid #C8C8C8;"
                class="pr-2 d-flex align-center"
            >
                <div
                    style="height:26px; line-height:13px; overflow: hidden; word-wrap:break-word;"
                >
                    {{item.name}}
                </div>
            </div>

            <!-- 대회정보 -->
            <div
                style="width:66px; font-size:10px; height:38px; border-bottom:1px solid #C8C8C8; color:#C8C9CF;"
                class="text-center pt-1"
            >
                <span
                    v-if="item.type == '리저널'"
                    style="color:#FFB900;"
                >
                    리저널
                </span>
                <span
                    v-if="item.type == '인터네셔널'"
                    style="color:#843CFF;"
                >
                    인터네셔널
                </span>
                <br />
                {{item.format}}
            </div>
        </v-sheet>

        <!-- 버튼 & 페이지네이션 -->
        <v-pagination
            class="mt-2 mb-10"
            :class="$vuetify.breakpoint.mobile? 'mobile' : 'mt-3 mb-1'"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="7"
        ></v-pagination>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            term: [
                "전체",
                "최근 1개월",
                "최근 3개월",
                "최근 6개월",
                "최근 9개월",
                "최근 12개월",
            ],

            tournament_type: [
                "전체",
                "리저널",
                "인터네셔널",
            ],

            country: [
                "전체",
                "US",
                "CL",
                "CA",
                "AR",
                "BR",
                "KR",
                "JP",
                "MX",
                "PR",
                "EE",
                "ID",
                "AU",
                "CN",
                "IN",
                "PE",
                "IE",
                "GB",
                "GR",
                "GT",
                "PH",
                "TH",
                "HK",
                "IT",
                "TW",
                "SV",
                "SG",
                "NZ",
                "DE",
                "PT",
                "FR",
                "UA",
                "BE",
                "PL",
                "ZA",
                "CH",
                "ES",
                "UY",
                "NL",
                "LT",
                "RU",
                "SE",
                "IL",
                "EC",
                "MY",
                "AE",
                "CR",
                "SK",
                "VE",
                "TT",
                "CZ",
                "PY",
                "BO",
                "HR",
                "CO",
                "FI",
                "RO",
                "KW",
                "RS",
                "BG"
            ],

            range: [
                "전체",
                "너정다",
                "LIMITLESS"
            ]
        },

        keyword: {
            term: "전체",
            tournament_type: "전체",
            country: "전체",
            range: "전체"
        },

        list: [],
        currentPageList: [],

        page: 1,
        pageLength: 1,

        loading: false
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*20,(this.page)*20)
        }
    },

    mounted(){
        // 검색
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post("/api/league/tournament/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res)

                if(this.keyword.range != "너정다")
                {
                    console.log(res)
                    this.list = res.data
                    this.currentPageList = this.list.slice(0,20)
                    this.pageLength = Math.ceil(this.list.length/20)
                    if(this.pageLength > 99) this.pageLength = 99
                    this.page = 1
                }
                else
                {
                    this.list = []
                    this.currentPageList = []
                }
            })
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

/* 화살표 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

/* LABEL */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 2px 0 0 2px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:0px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

::v-deep .mobile .v-label{
    font-size:9px !important;
    padding-bottom:1px;
    letter-spacing: -1px;
}

::v-deep .mobile .v-input--selection-controls__input{
    margin: 0 !important;
    padding: 0 !important;
}

</style>
<style scoped>
.diamond-box {
    width: 20px;
    height: 20px;
    background-color: blue; /* Change the color as needed */
    transform: rotate(45deg);
    border-radius: 5px;
}

.diamond-content {
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    display: block;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    min-width: 0px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    min-width: 0px !important;
    width: 24px !important;
    height: 26px !important;
    margin: 2px;
    font-size:12px;
    border-radius: 6px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}
</style>